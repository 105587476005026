<template>
    <div class="c-app-content" style="min-height: 500px;">
        <div class="row">
            <div class="col-12">
                <g-loading v-if="firstLoading" />
                <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                         title="Thông báo" :isReset="true" :isSelected="false" :isFooterFix="false">
                    <template slot="actionBefore">
                        <b-tabs class="c-tabs-button mr-1" small pills v-model="filterFormModel.type" @input="load">
                            <b-tab title-item-class='mb-1' v-for="(o, index) in params.types" :key="index" :title="index === null ? 'Tất cả' : o"></b-tab>
                        </b-tabs>
                    </template>
                    <template #cell(create_time)="{item}">
                        {{ item.create_time * 1000 | datetimeFormat }}
                    </template>
                </g-table>
            </div>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';

    export default {
        mixins: [list, crud],
        data: function () {
            return {
                service: '/site/notify',
                defaultFilterFormModel: {
                    type: 0,
                    keyword: ''
                },
                fields: [
                    {key: 'title', label: "Tiêu đề", class: "text-left"},
                    {key: 'content', label: "Nội dung", class: "text-left"},
                    {key: 'create_time', label: "Thời gian", class: "text-center"},
                ],
            }
        },
        mounted() {
            if (this.$store.state.user !== null) {
//                return this.$router.push("/manager/dashboard");
                return this.$router.push("/manager/dashboard-fixed");
            }
        },
    }
</script>